// extracted by mini-css-extract-plugin
export var actualLink = "styles-module--actualLink--39gsf";
export var fauxLink = "styles-module--fauxLink--3XvQB";
export var textWidth = "styles-module--textWidth--2A7Zu";
export var pageHeader = "styles-module--pageHeader--j3hZ1";
export var pageTitle = "styles-module--pageTitle--3baZA";
export var pageSubTitle = "styles-module--pageSubTitle--3vT1C";
export var unset = "styles-module--unset--1pNjz";
export var resetList = "styles-module--resetList--dcx-o";
export var tac = "styles-module--tac--2q7f6";
export var tal = "styles-module--tal--3dqBP";
export var tar = "styles-module--tar--3RETm";
export var noBoxShadow = "styles-module--no-box-shadow--1FJc8";
export var ptn = "styles-module--ptn--13XqZ";
export var pvn = "styles-module--pvn--2GL07";
export var pan = "styles-module--pan--3PY-7";
export var ptxs = "styles-module--ptxs--NUjJx";
export var pvxs = "styles-module--pvxs--VJcEh";
export var paxs = "styles-module--paxs--2zvpv";
export var pts = "styles-module--pts--1S7nu";
export var pvs = "styles-module--pvs--1lLAS";
export var pas = "styles-module--pas--1_Gf7";
export var ptm = "styles-module--ptm--3lxo6";
export var pvm = "styles-module--pvm--2vvcZ";
export var pam = "styles-module--pam--2cak9";
export var ptl = "styles-module--ptl--18eei";
export var pvl = "styles-module--pvl--33O_R";
export var pal = "styles-module--pal--2xDqm";
export var ptxl = "styles-module--ptxl--10bZt";
export var pvxl = "styles-module--pvxl--2KHg-";
export var paxl = "styles-module--paxl--3ZaPV";
export var prn = "styles-module--prn--3ubJG";
export var phn = "styles-module--phn--20msL";
export var prxs = "styles-module--prxs--1r9Bp";
export var phxs = "styles-module--phxs--3QSMR";
export var prs = "styles-module--prs--2j8HH";
export var phs = "styles-module--phs--31ZvG";
export var prm = "styles-module--prm--1gm5t";
export var phm = "styles-module--phm--2PerD";
export var prl = "styles-module--prl--1FjAu";
export var phl = "styles-module--phl--pQ_VO";
export var prxl = "styles-module--prxl--vF8h7";
export var phxl = "styles-module--phxl--1Xtw7";
export var pbn = "styles-module--pbn--2OfxD";
export var pbxs = "styles-module--pbxs--1PcyM";
export var pbs = "styles-module--pbs--3YNmT";
export var pbm = "styles-module--pbm--3iCU9";
export var pbl = "styles-module--pbl--3nMxV";
export var pbxl = "styles-module--pbxl--hL93q";
export var pln = "styles-module--pln--2FXRw";
export var plxs = "styles-module--plxs--3amu5";
export var pls = "styles-module--pls--1GKsC";
export var plm = "styles-module--plm--1umrM";
export var pll = "styles-module--pll--1--ZH";
export var plxl = "styles-module--plxl--D5Fxx";
export var mtn = "styles-module--mtn--RiUZW";
export var mvn = "styles-module--mvn--1LS3I";
export var man = "styles-module--man--1vs1C";
export var mtxs = "styles-module--mtxs--1Pom9";
export var mvxs = "styles-module--mvxs--2cVpX";
export var maxs = "styles-module--maxs--RRiJm";
export var mts = "styles-module--mts--1V_A1";
export var mvs = "styles-module--mvs--2-jDc";
export var mas = "styles-module--mas--2xkCG";
export var mtm = "styles-module--mtm--Oln55";
export var mvm = "styles-module--mvm--2bqxb";
export var mam = "styles-module--mam--ecTuS";
export var mtl = "styles-module--mtl--3gTcx";
export var mvl = "styles-module--mvl--3Ypdl";
export var mal = "styles-module--mal--Q7JQF";
export var mtxl = "styles-module--mtxl--2dQzN";
export var mvxl = "styles-module--mvxl--3t-Nk";
export var maxl = "styles-module--maxl--38Vve";
export var mrn = "styles-module--mrn--2jdPM";
export var mhn = "styles-module--mhn--o5f5h";
export var mrxs = "styles-module--mrxs--3j-A3";
export var mhxs = "styles-module--mhxs--3C4rF";
export var mrs = "styles-module--mrs--GRsaT";
export var mhs = "styles-module--mhs--2AtmB";
export var mrm = "styles-module--mrm--2mpLE";
export var mhm = "styles-module--mhm--2oD2A";
export var mrl = "styles-module--mrl--E398I";
export var mhl = "styles-module--mhl--1mpfu";
export var mrxl = "styles-module--mrxl--2CYMv";
export var mhxl = "styles-module--mhxl--1ITmi";
export var mbn = "styles-module--mbn--2RIIX";
export var mbxs = "styles-module--mbxs--IPk7W";
export var mbs = "styles-module--mbs--2eoy7";
export var mbm = "styles-module--mbm--2luqN";
export var mbl = "styles-module--mbl--25YR3";
export var mbxl = "styles-module--mbxl--HPY-E";
export var mln = "styles-module--mln--2ACK4";
export var mlxs = "styles-module--mlxs--8JByd";
export var mls = "styles-module--mls--3puMo";
export var mlm = "styles-module--mlm--6ZukN";
export var mll = "styles-module--mll--3ZKbK";
export var mlxl = "styles-module--mlxl--22PG8";
export var container = "styles-module--container--1cqCo";
export var copyButton = "styles-module--copyButton--2OqWr";