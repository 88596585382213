// extracted by mini-css-extract-plugin
export var actualLink = "styles-module--actualLink--2cbl8";
export var fauxLink = "styles-module--fauxLink--39Ajs";
export var textWidth = "styles-module--textWidth--2S60F";
export var pageHeader = "styles-module--pageHeader--21uhH";
export var pageTitle = "styles-module--pageTitle--3Yhha";
export var pageSubTitle = "styles-module--pageSubTitle--22kfn";
export var unset = "styles-module--unset--BqDKG";
export var resetList = "styles-module--resetList--3-Bkb";
export var tac = "styles-module--tac--D5Gce";
export var tal = "styles-module--tal--3jFNN";
export var tar = "styles-module--tar--1tqOI";
export var noBoxShadow = "styles-module--no-box-shadow--2oFJR";
export var ptn = "styles-module--ptn--CSu2W";
export var pvn = "styles-module--pvn--3sNpo";
export var pan = "styles-module--pan--1pyjS";
export var ptxs = "styles-module--ptxs--4g2j8";
export var pvxs = "styles-module--pvxs--1y5OA";
export var paxs = "styles-module--paxs--1grUj";
export var pts = "styles-module--pts--3cIzo";
export var pvs = "styles-module--pvs--3HR26";
export var pas = "styles-module--pas--2O6p-";
export var ptm = "styles-module--ptm--12M83";
export var pvm = "styles-module--pvm--QBryv";
export var pam = "styles-module--pam--2ijNX";
export var ptl = "styles-module--ptl--HciHy";
export var pvl = "styles-module--pvl--2jvZ2";
export var pal = "styles-module--pal--m34yW";
export var ptxl = "styles-module--ptxl--csg2h";
export var pvxl = "styles-module--pvxl--auOos";
export var paxl = "styles-module--paxl--1QkLX";
export var prn = "styles-module--prn--1gtTD";
export var phn = "styles-module--phn--LjfL0";
export var prxs = "styles-module--prxs--2lY5x";
export var phxs = "styles-module--phxs--abXyf";
export var prs = "styles-module--prs--2sZ6K";
export var phs = "styles-module--phs--1FaPB";
export var prm = "styles-module--prm--1Z8u5";
export var phm = "styles-module--phm--31Pi8";
export var prl = "styles-module--prl--3YKrw";
export var phl = "styles-module--phl--12VPh";
export var prxl = "styles-module--prxl---TZGH";
export var phxl = "styles-module--phxl--3MPN_";
export var pbn = "styles-module--pbn--3PIbt";
export var pbxs = "styles-module--pbxs--2f6U3";
export var pbs = "styles-module--pbs--1Ejh3";
export var pbm = "styles-module--pbm--2mUcK";
export var pbl = "styles-module--pbl--InYi4";
export var pbxl = "styles-module--pbxl--1WRUC";
export var pln = "styles-module--pln--22eY3";
export var plxs = "styles-module--plxs--1Io5_";
export var pls = "styles-module--pls--2GMvz";
export var plm = "styles-module--plm--5oL_K";
export var pll = "styles-module--pll--2Ga0z";
export var plxl = "styles-module--plxl--3De2D";
export var mtn = "styles-module--mtn--i7XRO";
export var mvn = "styles-module--mvn--2knk9";
export var man = "styles-module--man--LtW5q";
export var mtxs = "styles-module--mtxs--IK13r";
export var mvxs = "styles-module--mvxs---3phk";
export var maxs = "styles-module--maxs--3YiuF";
export var mts = "styles-module--mts--hM0Ld";
export var mvs = "styles-module--mvs--7nDQn";
export var mas = "styles-module--mas--1O9sr";
export var mtm = "styles-module--mtm--30Qed";
export var mvm = "styles-module--mvm--VpoMa";
export var mam = "styles-module--mam--1yYWB";
export var mtl = "styles-module--mtl--3Zzcs";
export var mvl = "styles-module--mvl--24_1T";
export var mal = "styles-module--mal--3qeb9";
export var mtxl = "styles-module--mtxl--9bww6";
export var mvxl = "styles-module--mvxl--QqqTB";
export var maxl = "styles-module--maxl--NpJTP";
export var mrn = "styles-module--mrn--j-w4o";
export var mhn = "styles-module--mhn--1oogV";
export var mrxs = "styles-module--mrxs--1p63Y";
export var mhxs = "styles-module--mhxs--24bfs";
export var mrs = "styles-module--mrs--1RtMF";
export var mhs = "styles-module--mhs--3m9qd";
export var mrm = "styles-module--mrm--3XYal";
export var mhm = "styles-module--mhm--2zBkD";
export var mrl = "styles-module--mrl--2GoFF";
export var mhl = "styles-module--mhl--3NzW3";
export var mrxl = "styles-module--mrxl--9A7On";
export var mhxl = "styles-module--mhxl--208Fj";
export var mbn = "styles-module--mbn--xdXwI";
export var mbxs = "styles-module--mbxs--33coC";
export var mbs = "styles-module--mbs--2sjDT";
export var mbm = "styles-module--mbm--3obg6";
export var mbl = "styles-module--mbl--1FLBq";
export var mbxl = "styles-module--mbxl--2V9F2";
export var mln = "styles-module--mln--3sZyz";
export var mlxs = "styles-module--mlxs--1WyZc";
export var mls = "styles-module--mls--11swS";
export var mlm = "styles-module--mlm--2-6d4";
export var mll = "styles-module--mll--1pdot";
export var mlxl = "styles-module--mlxl--3BJ3u";
export var layout = "styles-module--layout--ZEIYH";
export var container = "styles-module--container--1zKVS";
export var footer = "styles-module--footer--3dP-U";
export var builtIcon = "styles-module--builtIcon--31H4U";
export var footerSourceLink = "styles-module--footerSourceLink--2kQeS";
export var footerSourceText = "styles-module--footerSourceText--3GBUb";
export var footerIcon = "styles-module--footerIcon--uT91f";