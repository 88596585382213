// extracted by mini-css-extract-plugin
export var actualLink = "styles-module--actualLink--1US0q";
export var fauxLink = "styles-module--fauxLink--3p18W";
export var textWidth = "styles-module--textWidth--3r0kf";
export var pageHeader = "styles-module--pageHeader--2v3qu";
export var pageTitle = "styles-module--pageTitle--1i3A4";
export var pageSubTitle = "styles-module--pageSubTitle--3O5ma";
export var unset = "styles-module--unset--1PBIQ";
export var resetList = "styles-module--resetList--1qzGV";
export var tac = "styles-module--tac--3oJ4K";
export var tal = "styles-module--tal--1th4w";
export var tar = "styles-module--tar--1RYbt";
export var noBoxShadow = "styles-module--no-box-shadow--6YctK";
export var ptn = "styles-module--ptn--27ZUM";
export var pvn = "styles-module--pvn--3esGN";
export var pan = "styles-module--pan--3Ckgo";
export var ptxs = "styles-module--ptxs--25mmk";
export var pvxs = "styles-module--pvxs--2naGt";
export var paxs = "styles-module--paxs--1843F";
export var pts = "styles-module--pts--2s0v6";
export var pvs = "styles-module--pvs--2Ionn";
export var pas = "styles-module--pas--3rurN";
export var ptm = "styles-module--ptm--1PlQ_";
export var pvm = "styles-module--pvm--30loQ";
export var pam = "styles-module--pam--3QOVd";
export var ptl = "styles-module--ptl--3V-p1";
export var pvl = "styles-module--pvl--1-qO0";
export var pal = "styles-module--pal--x1bDI";
export var ptxl = "styles-module--ptxl--eJ-0z";
export var pvxl = "styles-module--pvxl--2751B";
export var paxl = "styles-module--paxl--3ELRC";
export var prn = "styles-module--prn--NEuq7";
export var phn = "styles-module--phn--A-WWX";
export var prxs = "styles-module--prxs--XW48V";
export var phxs = "styles-module--phxs--2YmL4";
export var prs = "styles-module--prs--rT8fN";
export var phs = "styles-module--phs--2KiEN";
export var prm = "styles-module--prm--1zgLe";
export var phm = "styles-module--phm--yFHkW";
export var prl = "styles-module--prl--fJg9z";
export var phl = "styles-module--phl--2hBg2";
export var prxl = "styles-module--prxl--34C7s";
export var phxl = "styles-module--phxl--1hbvw";
export var pbn = "styles-module--pbn--3sjJO";
export var pbxs = "styles-module--pbxs--1o428";
export var pbs = "styles-module--pbs--_mZss";
export var pbm = "styles-module--pbm--1dqJ4";
export var pbl = "styles-module--pbl--3KaeJ";
export var pbxl = "styles-module--pbxl--1Y_PY";
export var pln = "styles-module--pln--2s419";
export var plxs = "styles-module--plxs--sz6YO";
export var pls = "styles-module--pls--1vX3v";
export var plm = "styles-module--plm--Vl_mB";
export var pll = "styles-module--pll--3tZVy";
export var plxl = "styles-module--plxl--2unNN";
export var mtn = "styles-module--mtn--3v8cQ";
export var mvn = "styles-module--mvn--219nD";
export var man = "styles-module--man--2bBYD";
export var mtxs = "styles-module--mtxs--SWZyG";
export var mvxs = "styles-module--mvxs--hYPAi";
export var maxs = "styles-module--maxs--3s-Ao";
export var mts = "styles-module--mts--32VPn";
export var mvs = "styles-module--mvs--2FI-n";
export var mas = "styles-module--mas--1bGOi";
export var mtm = "styles-module--mtm--2gFn-";
export var mvm = "styles-module--mvm--2_9hD";
export var mam = "styles-module--mam--hSjgU";
export var mtl = "styles-module--mtl--3Ffs0";
export var mvl = "styles-module--mvl--1zuiG";
export var mal = "styles-module--mal--2Amn8";
export var mtxl = "styles-module--mtxl--5PNuw";
export var mvxl = "styles-module--mvxl--16E93";
export var maxl = "styles-module--maxl--1Q_xS";
export var mrn = "styles-module--mrn--1Zdgk";
export var mhn = "styles-module--mhn--35fJW";
export var mrxs = "styles-module--mrxs--2zKjH";
export var mhxs = "styles-module--mhxs--2cW1S";
export var mrs = "styles-module--mrs--1ftWw";
export var mhs = "styles-module--mhs--345Sj";
export var mrm = "styles-module--mrm--3XSSr";
export var mhm = "styles-module--mhm--3mLAj";
export var mrl = "styles-module--mrl--2rtmU";
export var mhl = "styles-module--mhl--2PPZd";
export var mrxl = "styles-module--mrxl--gmX-0";
export var mhxl = "styles-module--mhxl--1F05S";
export var mbn = "styles-module--mbn--1Malw";
export var mbxs = "styles-module--mbxs--eEVOh";
export var mbs = "styles-module--mbs--3fwAx";
export var mbm = "styles-module--mbm--3xwIB";
export var mbl = "styles-module--mbl--1pGTE";
export var mbxl = "styles-module--mbxl--1aIB7";
export var mln = "styles-module--mln--16TCn";
export var mlxs = "styles-module--mlxs--2oHtV";
export var mls = "styles-module--mls--1Hwf5";
export var mlm = "styles-module--mlm--2M1Lb";
export var mll = "styles-module--mll--LrnSp";
export var mlxl = "styles-module--mlxl--3_WuT";
export var borderStrap = "styles-module--borderStrap--1F725";
export var isRightSide = "styles-module--isRightSide--1Xqt7";